<template>
  <div class="realnamefrom">
    <el-steps :active="active" align-center class="header_step" style="margin-top: 40px;margin-bottom: 20px">
      <el-step title="企业实名" description="" />
      <el-step title="短信验证" description="" />
    </el-steps>
    <el-form v-show="active === 1" ref="form" label-position="top" :model="form" :rules="rules" class="form" align="left">
      <el-form-item label="企业名称（或组织机构名称）：" prop="business_name">
        <el-input v-model="form.business_name" placeholder="请输入企业名称（或组织机构名称）" />
      </el-form-item>
      <el-form-item label="企业统一社会信用代码：" prop="business_social_credit_code">
        <el-input v-model="form.business_social_credit_code" placeholder="请输入企业统一社会信用代码" type="text" />
      </el-form-item>
      <el-form-item label="法定代表人姓名：" prop="business_legal_person_name">
        <el-input v-model="form.business_legal_person_name" placeholder="请输入法定代表人姓名" type="text" />
      </el-form-item>
      <el-form-item label="法定代表人身份证件号码：" prop="business_legal_person_id_card">
        <el-input v-model="form.business_legal_person_id_card" placeholder="请输入法定代表人身份证件号码" type="text" />
      </el-form-item>
      <el-form-item label="法定代表人手机号：" prop="business_legal_person_tel">
        <el-input v-model="form.business_legal_person_tel" placeholder="请输入法定代表人手机号" type="text" />
      </el-form-item>
      <el-form-item label="CA年检联系手机号：" prop="business_ca_annual_tel">
        <el-input v-model="form.business_ca_annual_tel" placeholder="请输入CA年检联系手机号" type="text" />
      </el-form-item>
      <el-form-item label="" prop="agreement">
        <el-checkbox v-model="form.agreement" />&nbsp;&nbsp;&nbsp;<el-link disabled style="color: #606266;">已阅读并同意</el-link><el-link type="primary" :underline="false" @click="drawer = true">《数字证书使用协议》</el-link>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="NextStepButton" @click="NextStep">下一步</el-button>
      </el-form-item>
    </el-form>
    <el-form v-show="active === 2" ref="phone" label-position="top" :model="phone" :rules="phonerules" class="form" align="left">
      <el-form-item label="验证码：" prop="code">
        <el-input v-model="phone.code" placeholder="请输入验证码" type="text" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="NextStepButton" @click="onSubmit">下一步</el-button>
      </el-form-item>
    </el-form>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      style="width: 100%"
    >
      <Agreement @exit="exit" />
    </el-drawer>
    <CountDown ref="CountDown" />
  </div>
</template>

<script>
import '../../assets/public/public.css'
import { CheckTelCode, registeruser } from '../../api/user.js'
import { very } from '../../utils/SomethingHeld'
import CountDown from '../../components/countdown'
import Agreement from '../../components/agreement'
export default {
  name: 'Enterprise',
  components: {
    CountDown,
    Agreement
  },
  data() {
    var validataid_card = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else {
        const response = very(value)
        if (response.state) {
          callback()
        } else {
          callback(new Error(response.message))
        }
      }
    }
    var validataTel = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else {
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (reg_tel.test(value)) {
          callback()
        } else {
          callback(new Error('输入正确的手机号码格式'))
        }
      }
    }
    var validataCheckbox = (rule, value, callback) => {
      if (!value) {
        callback(new Error('必填'))
      } else {
        callback()
      }
    }
    return {
      form: {
        user_type: 2,
        business_name: '',
        business_social_credit_code: '',
        business_legal_person_name: '',
        business_legal_person_id_card: '',
        business_legal_person_tel: '',
        business_ca_annual_tel: '',
        agreement: false
      },
      drawer: false,
      rules: {
        business_name: [{ required: true, message: '必填', trigger: 'blur' }],
        business_social_credit_code: [{ required: true, message: '必填', trigger: 'blur' }],
        business_legal_person_name: [{ required: true, message: '必填', trigger: 'blur' }],
        business_legal_person_id_card: [{ required: true, validator: validataid_card, trigger: 'blur' }],
        business_legal_person_tel: [{ required: true, validator: validataTel, trigger: 'blur' }],
        business_ca_annual_tel: [{ required: true, message: '必填', trigger: 'blur' }],
        agreement: [{ required: true, validator: validataCheckbox, trigger: 'change' }]
      },
      phone: {
        code: ''
      },
      phonerules: {
        code: [{ required: true, message: '必填', trigger: 'blur' }]
      },
      active: 1
    }
  },
  methods: {
    NextStep() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.business_name = this.form.business_name.trim()
          this.form.business_social_credit_code = this.form.business_social_credit_code.trim()
          this.form.business_legal_person_name = this.form.business_legal_person_name.trim()
          this.form.business_legal_person_id_card = this.form.business_legal_person_id_card.trim()
          this.form.business_legal_person_tel = this.form.business_legal_person_tel.trim()
          this.form.business_ca_annual_tel = this.form.business_ca_annual_tel.trim()

          registeruser(this.form).then((response) => {
            if (response.code === 200) {
              this.active = 2
            } else {
              this.$message({
                message: response.message,
                type: 'error'
              })
            }
          }).catch((response) => {
          })
        }
      })
    },
    onSubmit() {
      this.$refs['phone'].validate((valid) => {
        if (valid) {
          // this.countdown = 30
          // this.countdowncount()
          this.$refs.CountDown.show()
          CheckTelCode({
            code: this.phone.code,
            tel: this.form.business_legal_person_tel,
            type: 2
          }).then((response) => {
            if (response.code === 200) {
              this.$router.push({
                path: 'EnterpriseSucess'
              })
              this.$message({
                message: '生成成功',
                type: 'success'
              })
            } else if (response.code === 202) {
              this.$message({
                message: '重复申请',
                type: 'error'
              })
            }
            this.$refs.CountDown.close()
          }).catch((response) => {
            this.$refs.CountDown.close()
          })
        }
      })
    },
    exit() {
      this.drawer = false
    }
  }
}
</script>

<style scoped>

</style>
